import { useCallback, useEffect, useRef } from "react";
import useInterval from "react-useinterval";
import Auth from "shared/services/Auth/Auth";
import SessionService from "shared/services/Session/Session";
import checkAndRefreshToken from "shared/utils/checkAndRefreshToken";

export interface IProps {
  auth: Auth;
  onRefreshToken: () => void;
  session: SessionService;
}

export const CHECK_INTERVAL = 1000 * 60; // Check every minute
// Change the above to 1000 * 10 to check every 10 seconds for testing of getting a new token

const CheckSession = ({ auth, onRefreshToken, session }: IProps) => {
  const isRefreshing = useRef(false);

  const checkAndRefreshTokenCallback = useCallback(async () => {
    if (isRefreshing.current) {
      return;
    }

    isRefreshing.current = true;
    try {
      await checkAndRefreshToken(auth, session);
      onRefreshToken();
    } catch {
      // If token refresh fails, logout
      session.clear();
      auth.logout();
    } finally {
      isRefreshing.current = false;
    }
  }, [auth, onRefreshToken, session]);

  useEffect(() => {
    checkAndRefreshTokenCallback();
  }, [checkAndRefreshTokenCallback]); // Check on load...

  useInterval(
    () => {
      checkAndRefreshTokenCallback();
    },
    session.isAuthenticated() ? CHECK_INTERVAL : null,
  ); // ...and periodically

  return null;
};

export default CheckSession;
